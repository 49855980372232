import React, {useEffect, useState} from 'react'
import { Container, Row, Col } from "react-bootstrap"
// local
import * as styles from "../../styles/bookdemo/Demo.module.scss"
import '../../styles/bookdemo/style.scss'
import union from '../../assets/images/homepage/union.svg'
import Layout from "../../components/Common/Layout"
import Seo from "../../components/Common/Seo"
import InfraCarousal from '../../components/Common/InfraCarousal'
import { Helmet } from 'react-helmet'

const Demo = (props) => {
  const [finalLoad, setFinalLoad] = useState(false)
  const pathName = props.location.pathname
  
  const loadForm = () => {
    if ('hbspt' in window) {
    window.hbspt.forms.create({
      region: "na1",
      portalId: "22580341",
      formId: "01e81478-3a37-41bc-a18c-87d8929fc212",
      target: "#demo-form"
    })
    setFinalLoad(true)
    }
  }

  useEffect(() => {
      loadForm()
  }, [])

  setTimeout(() => {
    loadForm()
  }, 1000)

  setTimeout(() => {
    if(!finalLoad) loadForm()
  }, 1400)
  return (
    <>
    <Layout pathName={pathName}>
      <Seo
        title="Lucidity Demo: Take the first step towards becoming truly cloud-ready."
        description="Request a live demo of Lucidity. Transform your block storage with 3 clicks and deliver 70% Cost Savings,
         99.999% Uptime,10X Performance, with zero code changes"
         canonicalUrl="https://www.lucidity.cloud/book-demo"
      />
      <section className={styles['demo']}>
      <img src={union} alt={union} className={styles['demo__union']} />
        <Container className={styles['demo__container']}>
            <Row className='align-items-center'>
              <Col lg={6} md={6} xs={12} className='text-left p-0'>
                <div className={`${styles['demo__heading']} justify-content-center`}>
                  <span>Take the first step towards being truly </span>
                  <span>cloud-ready</span>
                </div>
                <p>Transform your block storage with 1-click and deliver</p>
                <div className={styles['demo__features']}>
                  <span>99.999% Uptime</span>
                  <span>&#9679;</span>
                  <span>10X Performance</span>
                  <span>&#9679;</span>
                  <span>70% Cost Savings</span>
                  <span>&#9679;</span>
                  <span>No Code Changes</span>
                </div>
                </Col>
                <Col lg={6} md={6} xs={12} className='text-center p-0'>

                <div className="custom-form-bnr" id="demo-form">
                  <p className='initial_msg'>Schedule a Demo </p> 
                </div>
                </Col>
            </Row>
          </Container>
      </section>

        <section className={styles['demo_team_love']}>
          <InfraCarousal />
        </section>
      </Layout>
    </>
  )
}

export default Demo